import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom';

import api from "./components/services/api";
import { logout } from "./components/services/auth";

import './App.css';
import LandingPage from './components/LandingPage'
import QrLandingPage from './components/QrLandingPage'
import VisionSimulator from './components/visionsimulator/VisionSimulator'
import NightSimulator from './components/visionsimulator/NightSimulator'
// import LensQuestions from './components/visionsimulator/LensQuestions'
import Analytics from './components/user/Analytics'
import PatientInfo from './components/patientInfo/PatientInfo'
import Questionnaire from './components/patientInfo/Questionnaire'
import Paperwork from './components/paperwork/Paperwork'
// import UserNew from './components/user/UserNew'
import UserEdit from './components/user/UserEdit'
import UserAccess from './components/user/UserAccess'
import Login from './components/user/Login'
import LensList from './components/lens/LensList'
import CriFlacs from './components/cri/CriFlacs'
import ConsentList from './components/paperwork/consents/ConsentList'
import BedsheetEdit from './components/paperwork/bedsheet/BedsheetEdit'
import BiometerList from './components/biometer/BiometerList'
import EducationList from './components/education/EducationList'
import MyAccount from './components/user/MyAccount'
import About from './components/About'
// import PdfUpload from './components/paperwork/PdfUpload'
// import PdfRead from './components/paperwork/PdfRead'
import Tutorial1 from './components/tutorial/Tutorial1'
import Tutorial2 from './components/tutorial/Tutorial2'
import Tutorial3 from './components/tutorial/Tutorial3'
import Tutorial4 from './components/tutorial/Tutorial4'
import Tutorial5 from './components/tutorial/Tutorial5'
import TutorialVideo from './components/tutorial/TutorialVideo';
import Summary from './components/tutorial/Summary'
import Choice1 from './components/choices/Choice1';
import PaymentSuccess from './components/payments/PaymentSuccess';
import QrPurchased from './components/payments/QrPurchased';
import ExtraSession from './components/payments/ExtraSession';
import PremiumAccess from './components/purchaseUser/PremiumAccess';
import PatientSimulator from './components/visionsimulator/PatientSimulator';
import PatientNightSimulator from './components/visionsimulator/PatientNightSimulator';
import LensGuide from './components/tutorial/LensGuide';
import EyeInfo from './components/patientInfo/EyeInfo'
import PremiumSimulator from './components/visionsimulator/PremiumSimulator';
import PremiumNightSimulator from './components/visionsimulator/PremiumNightSimulator';
import LensGuideAdvanced from './components/tutorial/LensGuideAdvanced';
import AdvancedMode from './components/purchaseUser/AdvancedMode';
import CompareLenses from './components/tutorial/CompareLenses';
import TermsOfUse from './components/tutorial/TermsOfUse';



export default function App () {


  // Production blank parameters*******************
  const[power, setPower] = useState('')
  const[cylinder, setCylinder] = useState('')
  const[axis, setAxis] = useState('')
  const[add, setAdd] = useState('+2.50')
  const[codeStatus, setCodeStatus] = useState('')
  const[allergies, setAllergies] = useState('')
  const[side, setSide] = useState('')
  const[bcva, setBcva] = useState('')
  const[ns, setNs] = useState('2')
  const[bat, setBat] = useState('')
  const[biometerAstig, setBiometerAstig] = useState('')
  const[biometerAxis, setBiometerAxis] = useState('')
  const[biometerStdDev, setBiometerStdDev] = useState('')
  const[chordMuX, setChordMuX] = useState('')
  const[chordMuY, setChordMuY] = useState('')
  const[chordMuTotal, setChordMuTotal] = useState('')
  const[topographerAstig, setTopographerAstig] = useState('')
  const[topographerAxis, setTopographerAxis] = useState('')
  const[topographerSphAb, setTopographerSphAb] = useState('')
  const[topographerHoa, setTopographerHoa] = useState('')
  const[comaH, setComaH] = useState('')
  const[comaV, setComaV] = useState('')
  const[lensOption, setLensOption] = useState('Cataract vision')


  // //Testing default parameters*********************
  // const[power, setPower] = useState('-3.50')
  // const[cylinder, setCylinder] = useState('+0.50')
  // const[axis, setAxis] = useState('162')
  // const[add, setAdd] = useState('+2.50')
  // const[codeStatus, setCodeStatus] = useState('FULL')
  // const[allergies, setAllergies] = useState('NKDA')
  // const[side, setSide] = useState('RIGHT')
  // const[bcva, setBcva] = useState('40')
  // const[ns, setNs] = useState('2')
  // const[bat, setBat] = useState('50')
  // const[biometerAstig, setBiometerAstig] = useState('0.19')
  // const[biometerAxis, setBiometerAxis] = useState('49')
  // const[biometerStdDev, setBiometerStdDev] = useState('')
  // const[chordMuX, setChordMuX] = useState('0.1')
  // const[chordMuY, setChordMuY] = useState('0.0')
  // const[chordMuTotal, setChordMuTotal] = useState('0.0')
  // const[topographerAstig, setTopographerAstig] = useState('0.9')
  // const[topographerAxis, setTopographerAxis] = useState('52.3')
  // const[topographerSphAb, setTopographerSphAb] = useState('0.385')
  // const[topographerHoa, setTopographerHoa] = useState('0.183')
  // const[comaH, setComaH] = useState('0.2')
  // const[comaV, setComaV] = useState('0.3')
  // const[lensOption, setLensOption] = useState('Cataract vision')
  
  //*************************************************************
  const[plannedLens, setPlannedLens] = useState('')
  const[lensTargetNear, setLensTargetNear] = useState(false)


  //Parameters for selected lens option
  const[lensName, setLensName] = useState('')
  const[lensModel, setLensModel] = useState('')
  const[lensLocation, setLensLocation] = useState('')
  const[lensHospitalFee, setLensHospitalFee] = useState(0)
  const[lensPhysicianFee, setLensPhysicianFee] = useState(0)
  const[lensKAstigMin, setLensKAstigMin] = useState('')
  const[lensKAstigMax, setLensKAstigMax] = useState('')
  const[lensKAstigResidual, setLensKAstigResidual] = useState('')
  const[lensCorrectsIntermediateVision, setLensCorrectsIntermediateVision] = useState('')
  const[lensCorrectsNearVision, setLensCorrectsNearVision] = useState('')
  const[lensGlare, setLensGlare] = useState('')
  const[lensSphAb, setLensSphAb] = useState('')
  const[lensKSphAbResidual, setLensKSphAbResidual] = useState('')
  const[lensTopographerHoaCutoff, setLensTopographerHoaCutoff] = useState('')
  const[lensChordMu, setLensChordMu] = useState('')
  const[lensBiometerStdDev, setLensBiometerStdDev] = useState('')
  const[lensBiometerTopographerAstigCutoff, setLensBiometerTopographerAstigCutoff] = useState('')
  const[lensBiometerTopographerAxisCutoff, setLensBiometerTopographerAxisCutoff] = useState('')

  const[lensCriMinAstig, setLensCriMinAstig] = useState('')
  const[lensCriMaxAstig, setLensCriMaxAstig] = useState('')
  const[lensCriRequire, setLensCriRequire] = useState('')
  const[lensFlacsRequire, setLensFlacsRequire] = useState('')
  const[lensCriFee, setLensCriFee] = useState('')
  const[lensFlacsFee, setLensFlacsFee] = useState('')

  const[lensConsentId, setLensConsentId] = useState([])
  // const[lensContraindications, setLensContraindications] = useState('')

  //Hooks to store data from database
  const[lensData, setLensData] = useState('')  //Hook 50
  const[choseBestLens, setChoseBestLens] = useState('')
  const[whyLens, setWhyLens] = useState('')
  const[ptLensList, setPtLensList] = useState([])
  const[sulcusLens, setSulcusLens] = useState('')
  const[contraindications, setContraindications] = useState([])
  
  const[consentData, setConsentData] = useState('')
  const[bedsheetData, setBedsheetData] = useState('') //hook 57 in state
  const [bedsheetId, setBedsheetId] = useState('')
  const [bedsheetUse, setBedsheetUse] = useState('')
  const [bedsheetName, setBedsheetName] = useState('')

  const[biometer, setBiometer] = useState('')
  const[topographer, setTopographer] = useState('')
  const[equipmentPreference, setEquipmentPreference] = useState('')
  const[sphAbTarget, setSphAbTarget] = useState('')
  const[hoaMaxPreference, setHoaMaxPreference] = useState('')

  const[physicianName, setPhysicianName] = useState('')
  const[clinicName, setClinicName] = useState('')
  const[clinicStreet, setClinicStreet] = useState('')
  const[clinicCity, setClinicCity] = useState('')
  const[clinicState, setClinicState] = useState('')
  const[clinicZip, setClinicZip] = useState('')
  const[clinicPhone, setClinicPhone] = useState('')
  const[clinicFax, setClinicFax] = useState('')

  // const[patientName, setPatientName] = useState('')

  const[cri, setCri] = useState(false)  //Indicates use CRI for selected lens
  const[criOffer, setCriOffer] = useState('')
  const[criBioTopoAstigMax, setCriBioTopoAstigMax] = useState('')
  const[criBioTopoAxisMax, setCriBioTopoAxisMax] = useState('')
  const[criConsentId, setCriConsentId] = useState([])  // hook 78
  const[criAllowToggle, setCriAllowToggle] = useState('')
  const[criAlwaysOn, setCriAlwaysOn] = useState('')

  const [flacs, setFlacs] = useState(false) //Indicates use FLACS for selected lens
  const [flacsOffer, setFlacsOffer] = useState('')
  const [flacsConsentId, setFlacsConsentId] = useState([])  //hook 83
  const [flacsAllowToggle, setFlacsAllowToggle] = useState('')
  const [flacsAlwaysOn, setFlacsAlwaysOn] = useState('')

  const [oopTotal, setOopTotal] = useState('')
  const [oopPhysician, setOopPhysician] = useState('')
  const [oopHospital, setOopHospital] = useState('')

  const [useTutorial, setUseTutorial] = useState(false)  //hook 89
  const [useVideo, setUseVideo] = useState(false)
  const [educationPost, setEducationPost] = useState('')
  const [videoData, setVideoData] = useState('')

  //QR encryption stuff  (hooks 87-90?)
  const[encryptionKey, setEncryptionKey]=useState('')
  const[cipherText, setCipherText]=useState('')
  const[qrToken, setQrToken]=useState('')
  const[userType, setUserType]=useState('')

  const[staticData, setStaticData] = useState('')
  const[otherSoon, setOtherSoon] = useState('')
  const[basicLensOption, setBasicLensOption] = useState('Cataract vision')
  const[basicLensData, setBasicLensData] = useState('')
  const[paymentQr, setPaymentQr] = useState('')
  const[haveAstig, setHaveAstig] = useState('false')
  const[staticBiometerAstig, setStaticBiometerAstig] = useState('0.1')
  const[staticTopographerAstig, setStaticTopographerAstig] = useState('0.2')
  const[selectedColor, setSelectedColor] = useState('#000')
  const[onlyUpToDate, setOnlyUpToDate] = useState(true)
  const[onlyCompatible, setOnlyCompatible] = useState(true)
  const[lensOptionTwo, setLensOptionTwo] = useState('Cataract vision')


  const [seeTerms, setSeeTerms] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)


  //*****************************************************************

  //Temporary placeholder until I add it to the database and make it's own component that doctors can edit


  const[cataractAddOn, setCataractAddOn]=useState([
    //Cornea addOns
    {name: 'Pterygium surgery', cpt: '65426',  icd: 'H11.02', value: 'false'},
    {name: 'DMEK / DSAEK for Fuchs Endothelial dystrophy', cpt: '65756',  icd: 'H18.51', value: 'false'},
    {name: 'DMEK / DSAEK for Pseudophakic Bullous Keratopathy (PKB)', cpt: '65756',  icd: 'H18.1', value: 'false'},
    {name: 'DMEK / DSAEK for failed corneal transplant', cpt: '65756',  icd: 'T86.841', value: 'false'},
    {name: 'DMEK / DSAEK for secondary corneal edema', cpt: '65756',  icd: 'H18.23', value: 'false'},
    {name: 'PKP / DALK for unstable keratoconus (phakic)', cpt: '65730',  icd: 'H18.62', value: 'false'},
    {name: 'PKP / DALK for unstable keratoconus (pseudophakic)', cpt: '65755',  icd: 'H18.62', value: 'false'},
    {name: 'PKP / DALK for unstable keratoconus (aphakic)', cpt: '65750',  icd: 'H18.62', value: 'false'},
    {name: 'PKP / DALK for corneal ectasia (phakic)', cpt: '65730',  icd: 'H18.71', value: 'false'},
    {name: 'PKP / DALK for corneal ectasia (pseudophakic)', cpt: '65755',  icd: 'H18.71', value: 'false'},
    {name: 'PKP / DALK for corneal ectasia (aphakic)', cpt: '65750',  icd: 'H18.71', value: 'false'},
    {name: 'PKP / DALK for failed corneal transplant (phakic)', cpt: '65730',  icd: 'T86.841', value: 'false'},
    {name: 'PKP / DALK for failed corneal transplant (pseudophakic)', cpt: '65755',  icd: 'T86.841', value: 'false'},
    {name: 'PKP / DALK for failed corneal transplant (aphakic)', cpt: '65750',  icd: 'T86.841', value: 'false'},
    {name: 'Anterior vitrectomy for vitreous prolapse', cpt: '67010',  icd: 'H43.0', value: 'false'},
    
    // glaucoma add ons
    {name: 'Goniotomy', cpt: '65820', icd: 'H40.11', value: 'false'},
    {name: 'iStent', cpt: '66989',  icd: 'H40.11', value: 'false'},
    {name: 'Xen stent', cpt: '66183',  icd: 'H40.11', value: 'false'},
    {name: 'Ahmed', cpt: '66180',  icd: 'H40.11', value: 'false'},
    {name: 'Baerveldt', cpt: '66180',  icd: 'H40.11', value: 'false'},

    // default on for cataract surgery.  If clicked off, allows skipping to paperwork
    {name: 'Cataract surgery', cpt: '66984',  icd: 'H25.1', value: 'true'},
  ])


  //====Visual function quesionts=================================================
  const[q1, setQ1] = useState('')
  const[q2, setQ2] = useState('')
  const[q3, setQ3] = useState('')
  const[q4, setQ4] = useState('')
  const[q5, setQ5] = useState('')
  const[q6, setQ6] = useState('')
  const[q7, setQ7] = useState('')
  const[q8, setQ8] = useState('')
  const[q9, setQ9] = useState('')
  const[q10, setQ10] = useState('')
  const[q11, setQ11] = useState('')
  const[q12, setQ12] = useState('')
  const[q13, setQ13] = useState('')
  const[q14, setQ14] = useState('')




  //Get all lenses with parameters from database
    const getLenses = async () => {
      try {
        const response = await api.get("/lens");
        const { data } = response;
    
        setLensData(data.rows);
    
        const sulcusLens = data.rows.find((lens) => lens.lens_location === 'Sulcus');
        if (sulcusLens) {
          setSulcusLens(sulcusLens.lens_name);
        }
      } catch (error) {
        if (error.message === 'Request failed with status code 401') {
          logout();
        }
        console.error(`Error in getLenses: ${error.message}`);
      }
    };



    useEffect(() => {
        getLenses();
      }, []);

  //Get all lens contraindications
  //See ContraindicationsPtInfo

  //"Get all patient data" is not necessary because it is not stored in the database (see PatientInfo component below)


  //Get all equipment data
  const getEquipment = async() => {

      try {
        const response = await api.get(`/equipment`);

        setBiometer(response.data.rows[0].biometer_name)
        setTopographer(response.data.rows[0].topographer_name)
        setEquipmentPreference(response.data.rows[0].equipment_preference)
        setSphAbTarget(response.data.rows[0].sph_ab_target)
        setHoaMaxPreference(response.data.rows[0].hoa_max_preference)

      } catch (err) {
        console.error(err.message)
      }
    }

    useEffect(() => {
        getEquipment();
      }, []);


  //Get all clinic data
  const getUser = async() => {

      try {
        const response = await api.get("/user");

        setPhysicianName(response.data.rows[0].physician_name)
        setClinicName(response.data.rows[0].clinic_name)
        setClinicStreet(response.data.rows[0].clinic_street)
        setClinicCity(response.data.rows[0].clinic_city)
        setClinicState(response.data.rows[0].clinic_state)
        setClinicZip(response.data.rows[0].clinic_zip)
        setClinicPhone(response.data.rows[0].clinic_phone)
        setClinicFax(response.data.rows[0].clinic_fax)

      } catch (err) {
        console.error(err.message)
      }
    }

    useEffect(() => {
        getUser();
      }, []);


    //Get CRI data
    const getCriFlacs = async() => {

        try {
          const response = await api.get("/criflacs");

          setCriOffer(response.data.rows[0].cri_offer)
          setCriBioTopoAstigMax(response.data.rows[0].cri_bio_topo_astig_max)
          setCriBioTopoAxisMax(response.data.rows[0].cri_bio_topo_axis_max)
          setCriConsentId(response.data.rows[0].cri_consent)
          setFlacsOffer(response.data.rows[0].flacs_offer)
          setFlacsConsentId(response.data.rows[0].flacs_consent)

        } catch (err) {
          console.error(err.message)
        }
      }

      useEffect(() => {
          getCriFlacs();
        }, []);


    //Get consent data
    const getConsent = async() => {

        try {
          const response = await api.get("/consent");

          setConsentData(response.data.rows)

        } catch (err) {
          console.error(err.message)
        }
      }


      useEffect(() => {
          getConsent();
        }, []);
    

    //Get besheet data
    const getBedsheet = async() => {

        try {
          const response = await api.get("/bedsheet");
          if(response.data.rows.length > 0){
            setBedsheetData(response.data.rows[0].bedsheet_data)
            setBedsheetId(response.data.rows[0].bedsheet_id)
            setBedsheetName(response.data.rows[0].bedsheet_name)
            setBedsheetUse(response.data.rows[0].bedsheet_use)
          }
          else {
            setBedsheetData([{split: 'false', isFocused: 'true', data: ''},])
            setBedsheetId('')
            setBedsheetName('')
            setBedsheetUse('Yes')
          }
        } catch (err) {
          console.error(err.message)
        }
      }


      useEffect(() => {
          getBedsheet();
        }, []);


    //Get video data
    const getVideo = async() => {

        try {
          const response = await api.get("/video");

          setVideoData(response.data.rows)

        } catch (err) {
          console.error(err.message)
        }
      }


      useEffect(() => {
          getVideo();
        }, []);


    //Get education data
    const getEducation = async() => {

        try {
          const response = await api.get("/education");
          if(response.data.rows.length > 0){
            setEducationPost(false)
            setUseTutorial(response.data.rows[0].use_tutorial)
            setUseVideo(response.data.rows[0].use_video)
          } else {
            setEducationPost(true)
          }

        } catch (err) {
          console.error(err.message)
        }
      }


      useEffect(() => {
          getEducation();
        }, []);



  useEffect(() => { window.process = { ...window.process, }; }, []);

  
  
  // =================Verify user is authorized=============
  const [authVerify, setAuthVerify] = useState('')
  
  const getAuthVerify = async() => {

    try {
      const response = await api.get(`/authverify`);

      setAuthVerify(true)
      
    } catch (err) {
      console.error(err.message)
      setAuthVerify(false)
    }

  }

  useEffect(() => {
    getAuthVerify();
    }, []);

  const handleLoginSuccess = () => {
      getAuthVerify();
  };
  
  // =================set vision simulation scene=============
  // const [sceneData, setSceneData] = useState('')

  // const [sceneNear, setSceneNear]=useState('')
  // const [sceneIntermediate, setSceneIntermediate]=useState('')
  // const [sceneDistance, setSceneDistance]=useState('')

  // const [sceneSelected, setSceneSelected]=useState('Office building')

  // let sceneOptions = ['Office building', 'Gardening', 'Driving', 'Reading', 'Family history']

  // const getScene = async() => {

  //     try {
  //       const response = await api.get(`/scene`);

  //       setSceneData(response.data.rows)

  //       response.data.rows.map(m => (
  //           (m.scene_name === sceneSelected)
  //           ? setSceneNear(m.scene_near)
  //           : null
  //         ))

  //       response.data.rows.map(m => (
  //           (m.scene_name === sceneSelected)
  //           ? setSceneIntermediate(m.scene_intermediate)
  //           : null
  //         ))

  //       response.data.rows.map(m => (
  //           (m.scene_name === sceneSelected)
  //           ? setSceneDistance(m.scene_distance)
  //           : null
  //         ))

  //     } catch (err) {
  //       console.error(err.message)
  //     }

  //   }


    // useEffect(() => {
    //     getScene();
    //   }, [sceneSelected]);


    // sceneSelected={sceneSelected}
    // setSceneSelected={setSceneSelected}
    // sceneNear={sceneNear}
    // sceneIntermediate={sceneIntermediate}
    // sceneDistance={sceneDistance}
    // sceneOptions={sceneOptions}

//============================================================================



  return(
    <Router>
      <Routes>
        
        <Route 
          path="/" 
          element={
            <Tutorial5
              userType={userType}
              setUserType={setUserType}
              authVerify={authVerify}
              seeTerms={seeTerms}
              setSeeTerms={setSeeTerms}
              acceptTerms={acceptTerms}
              setAcceptTerms={setAcceptTerms}
            />
            // <LandingPage
            //   userType={userType}
            //   setUserType={setUserType}
            //   authVerify={authVerify}
            // /> 
          }
          />

        <Route 
          path="/termsofuse" 
          element={
            <TermsOfUse
            /> 
          }
          />

        <Route 
          path="/qr/:id" 
          element={
            <QrLandingPage
              userType={userType}
              setUserType={setUserType}
              setSide={setSide}
              setPower={setPower}
              setCylinder={setCylinder}       
              setAxis={setAxis}
              setAdd={setAdd}
              setBcva={setBcva}
              setNs={setNs}
              setBat={setBat}
              setBiometerAstig={setBiometerAstig}
              setBiometerAxis={setBiometerAxis}
              setChordMuTotal={setChordMuTotal}
              setTopographerAstig={setTopographerAstig}
              setTopographerAxis={setTopographerAxis}
              setTopographerSphAb={setTopographerSphAb}
              setTopographerHoa={setTopographerHoa}
              setCodeStatus={setCodeStatus}
              setAllergies={setAllergies}
              setLensOption={setLensOption}
              setContraindications={setContraindications}
              setLensData={setLensData}
              setSulcusLens={setSulcusLens}
              setBiometer={setBiometer}
              setTopographer={setTopographer}
              setEquipmentPreference={setEquipmentPreference}
              setSphAbTarget={setSphAbTarget}
              setHoaMaxPreference={setHoaMaxPreference}
              setCriOffer={setCriOffer}
              setCriBioTopoAstigMax={setCriBioTopoAstigMax}
              setCriBioTopoAxisMax={setCriBioTopoAxisMax}
              setCriConsentId={setCriConsentId}
              setFlacsOffer={setFlacsOffer}
              setFlacsConsentId={setFlacsConsentId}
              setConsentData={setConsentData}
              useTutorial={useTutorial}
              setUseTutorial={setUseTutorial}
              useVideo={useVideo}
              setUseVideo={setUseVideo}
              setVideoData={setVideoData}
              setQ1={setQ1}
              setQ2={setQ2}
              setQ3={setQ3}
              setQ4={setQ4}
              setQ5={setQ5}
              setQ6={setQ6}
              setQ7={setQ7}
              setQ8={setQ8}
              setQ9={setQ9}
              setQ10={setQ10}
              setQ11={setQ11}
              setQ12={setQ12}
              setQ13={setQ13}
              setQ14={setQ14}
              
              cataractAddOn={cataractAddOn}
              setCataractAddOn={setCataractAddOn}

              setBedsheetData={setBedsheetData}
              setBedsheetId={setBedsheetId}
              setBedsheetUse={setBedsheetUse}
              setBedsheetName={setBedsheetName}

              authVerify={authVerify}
              />
              }
          />
        
        <Route 
          path="/patientinfo" 
          element={
            // authVerify === false 
            // ? <LandingPage
            //     userType={userType}
            //     setUserType={setUserType}
            //     authVerify={authVerify}
            //   /> 
            // : authVerify === true
            // ?
            <PatientInfo
              power={power}
              setPower={setPower}
              cylinder={cylinder}
              setCylinder={setCylinder}
              axis={axis}
              setAxis={setAxis}
              add={add}
              setAdd={setAdd}
              codeStatus={codeStatus}
              setCodeStatus={setCodeStatus}
              allergies={allergies}
              setAllergies={setAllergies}
              side={side}
              setSide={setSide}
              bcva={bcva}
              setBcva={setBcva}
              ns={ns}
              setNs={setNs}
              bat={bat}
              setBat={setBat}
              biometer={biometer}
              biometerAstig={biometerAstig}
              setBiometerAstig={setBiometerAstig}
              biometerAxis={biometerAxis}
              setBiometerAxis={setBiometerAxis}
              biometerStdDev={biometerStdDev}
              setBiometerStdDev={setBiometerStdDev}
              chordMuX={chordMuX}
              setChordMuX={setChordMuX}
              chordMuY={chordMuY}
              setChordMuY={setChordMuY}
              chordMuTotal={chordMuTotal}
              setChordMuTotal={setChordMuTotal}
              topographer={topographer}
              topographerAstig={topographerAstig}
              setTopographerAstig={setTopographerAstig}
              topographerAxis={topographerAxis}
              setTopographerAxis={setTopographerAxis}
              topographerSphAb={topographerSphAb}
              setTopographerSphAb={setTopographerSphAb}
              topographerHoa={topographerHoa}
              setTopographerHoa={setTopographerHoa}
              comaH={comaH}
              setComaH={setComaH}
              comaV={comaV}
              setComaV={setComaV}
              lensOption={lensOption}
              setLensOption={setLensOption}
              contraindications={contraindications}
              setContraindications={setContraindications}
              physicianName={physicianName}
              setUserType={setUserType}

              cataractAddOn={cataractAddOn}
              setCataractAddOn={setCataractAddOn}
            />
          // : null
          }
        />

        <Route 
          path="/questionnaire" 
          element={
            // authVerify === false 
            // ? <LandingPage
            //     userType={userType}
            //     setUserType={setUserType}
            //     authVerify={authVerify}
            //   /> 
            // : authVerify === true
            // ?
            <Questionnaire
              side={side}
              q1={q1}
              setQ1={setQ1}
              q2={q2}
              setQ2={setQ2}
              q3={q3}
              setQ3={setQ3}
              q4={q4}
              setQ4={setQ4}
              q5={q5}
              setQ5={setQ5}
              q6={q6}
              setQ6={setQ6}
              q7={q7}
              setQ7={setQ7}
              q8={q8}
              setQ8={setQ8}
              q9={q9}
              setQ9={setQ9}
              q10={q10}
              setQ10={setQ10}
              q11={q11}
              setQ11={setQ11}
              q12={q12}
              setQ12={setQ12}
              q13={q13}
              setQ13={setQ13}
              q14={q14}
              setQ14={setQ14}
            />
          // : null    
          }
        />
        
        
        <Route 
          path='/choice1' 
          element={
            // authVerify === false 
            // ? <LandingPage
            //     userType={userType}
            //     setUserType={setUserType}
            //     authVerify={authVerify}
            //   /> 
            // : authVerify === true
            // ?
            <Choice1
              power={power.toLowerCase().replace(/\s+/gm,'')}
              cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
              axis={axis}
              add={add}
              codeStatus={codeStatus}
              allergies={allergies}
              side={side}
              bcva={bcva}
              ns={ns}
              bat={bat}
              
              biometer={biometer}
              biometerAstig={biometerAstig}
              biometerAxis={biometerAxis}
              chordMuTotal={chordMuTotal}
              
              topographer={topographer}
              topographerAstig={topographerAstig}
              topographerAxis={topographerAxis}
              topographerSphAb={topographerSphAb}
              topographerHoa={topographerHoa}
              equipmentPreference={equipmentPreference}
              sphAbTarget={sphAbTarget}
              hoaMaxPreference={hoaMaxPreference}


              lensOption={lensOption}
              lensData={lensData}
              contraindications={contraindications}
              
              criOffer={criOffer}
              criBioTopoAstigMax={criBioTopoAstigMax}
              criBioTopoAxisMax={criBioTopoAxisMax}
              criConsentId={criConsentId}
              flacsOffer={flacsOffer}
              flacsConsentId={flacsConsentId}

              encryptionKey={encryptionKey}
              setEncryptionKey={setEncryptionKey}
              cipherText={cipherText}
              setCipherText={setCipherText}
              qrToken={qrToken}
              setQrToken={setQrToken}
            
              lensModel={lensModel}
              lensLocation={lensLocation}
              sulcusLens={sulcusLens}
              
              lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
              lensCorrectsNearVision={lensCorrectsNearVision}
              lensTargetNear={lensTargetNear}
              lensKAstigMin={lensKAstigMin}
              cri={cri}
              flacs={flacs}

              cataractAddOn={cataractAddOn}

              q1={q1}
              q2={q2}
              q3={q3}
              q4={q4}
              q5={q5}
              q6={q6}
              q7={q7}
              q8={q8}
              q9={q9}
              q10={q10}
              q11={q11}
              q12={q12}
              q13={q13}
              q14={q14}

              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
              videoData={videoData}

            />
          // : null
          }
        />

        <Route 
          path='/tutorialvideo' 
          element={
            <TutorialVideo
              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
              videoData={videoData}
          />
        }/>
        
        <Route 
          path='/tutorial1' 
          element={
            <Tutorial1
              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
          />
        }/>
       
        <Route 
          path='/tutorial2' 
          element={
            <Tutorial2
          />
        }/>
       
        <Route 
          path='/tutorial3' 
          element={
            <Tutorial3
              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
              authVerify={authVerify}
          />
        }/>
      
        <Route 
          path='/tutorial4' 
          element={
            <Tutorial4
              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
          />
        }/>
        
        <Route 
          path='/tutorial5' 
          element={
            <Tutorial5
              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
              seeTerms={seeTerms}
              setSeeTerms={setSeeTerms}
              acceptTerms={acceptTerms}
              setAcceptTerms={setAcceptTerms}
          />
        }/>
        
        <Route 
          path="/visionsimulator" 
          element={
            // authVerify === false 
            // ? <LandingPage
            //     userType={userType}
            //     setUserType={setUserType}
            //     authVerify={authVerify}
            //   /> 
            // : authVerify === true
            // ?
            <VisionSimulator
              power={power.toLowerCase().replace(/\s+/gm,'')}
              cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
              axis={axis}
              add={add}
              bcva={bcva}
              ns={ns}
              side={side}

              biometer={biometer}
              biometerAstig={biometerAstig}
              biometerAxis={biometerAxis}
              biometerStdDev={biometerStdDev}

              chordMuTotal={chordMuTotal}

              topographer={topographer}
              topographerAstig={topographerAstig}
              topographerAxis={topographerAxis}
              topographerSphAb={topographerSphAb}
              topographerHoa={topographerHoa}

              equipmentPreference={equipmentPreference}
              sphAbTarget={sphAbTarget}
              hoaMaxPreference={hoaMaxPreference}

              lensData={lensData}
              lensOption={lensOption}
              setLensOption={setLensOption}
              choseBestLens={choseBestLens}
              setChoseBestLens={setChoseBestLens}

              lensName={lensName}
              setLensName={setLensName}
              lensModel={lensModel}
              setLensModel={setLensModel}
              lensLocation={lensLocation}
              setLensLocation={setLensLocation}
              lensHospitalFee={lensHospitalFee}
              setLensHospitalFee={setLensHospitalFee}
              lensPhysicianFee={lensPhysicianFee}
              setLensPhysicianFee={setLensPhysicianFee}
              lensSphAb={lensSphAb}
              setLensSphAb={setLensSphAb}
              lensKSphAbResidual={lensKSphAbResidual}
              setLensKSphAbResidual={setLensKSphAbResidual}
              lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
              setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
              lensCorrectsNearVision={lensCorrectsNearVision}
              setLensCorrectsNearVision={setLensCorrectsNearVision}
              lensGlare={lensGlare}
              setLensGlare={setLensGlare}
              lensTopographerHoaCutoff={lensTopographerHoaCutoff}
              setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
              lensKAstigMax={lensKAstigMax}
              setLensKAstigMax={setLensKAstigMax}
              lensKAstigMin={lensKAstigMin}
              setLensKAstigMin={setLensKAstigMin}
              lensKAstigResidual={lensKAstigResidual}
              setLensKAstigResidual={setLensKAstigResidual}
              lensChordMu={lensChordMu}
              setLensChordMu={setLensChordMu}
              lensBiometerStdDev={lensBiometerStdDev}
              setLensBiometerStdDev={setLensBiometerStdDev}
              lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
              setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
              lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
              setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
              setLensConsentId={setLensConsentId}

              contraindications={contraindications}
              lensTargetNear={lensTargetNear}
              setLensTargetNear={setLensTargetNear}

              lensCriFee={lensCriFee}
              setLensCriFee={setLensCriFee}
              lensFlacsFee={lensFlacsFee}
              setLensFlacsFee={setLensFlacsFee}

              criOffer={criOffer}
              lensCriMinAstig={lensCriMinAstig}
              setLensCriMinAstig={setLensCriMinAstig}
              lensCriMaxAstig={lensCriMaxAstig}
              setLensCriMaxAstig={setLensCriMaxAstig}
              lensCriRequire={lensCriRequire}
              setLensCriRequire={setLensCriRequire}
              lensFlacsRequire={lensFlacsRequire}
              setLensFlacsRequire={setLensFlacsRequire}
              cri={cri}
              setCri={setCri}
              criAlwaysOn={criAlwaysOn}
              setCriAlwaysOn={setCriAlwaysOn}
              criAllowToggle={criAllowToggle}
              setCriAllowToggle={setCriAllowToggle}
              criBioTopoAstigMax={criBioTopoAstigMax}
              criBioTopoAxisMax={criBioTopoAxisMax}

              flacs={flacs}
              setFlacs={setFlacs}
              flacsOffer={flacsOffer}
              flacsAlwaysOn={flacsAlwaysOn}
              setFlacsAlwaysOn={setFlacsAlwaysOn}
              flacsAllowToggle={flacsAllowToggle}
              setFlacsAllowToggle={setFlacsAllowToggle}

              physicianName={physicianName}

              oopTotal={oopTotal}
              setOopTotal={setOopTotal}
              oopPhysician={oopPhysician}
              setOopPhysician={setOopPhysician}
              oopHospital={oopHospital}
              setOopHospital={setOopHospital}

              setPtLensList={setPtLensList}

              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              onlyCompatible={onlyCompatible}
              setOnlyCompatible={setOnlyCompatible}

              userType={userType}
              useVideo={useVideo}
              useTutorial={useTutorial}

              staticLensListOptions={staticLensListOptions}
            />
            // : null
          }
        />


        <Route 
          path="/nightsimulator" 
          element={
            // authVerify === false 
            // ? <LandingPage
            //     userType={userType}
            //     setUserType={setUserType}
            //     authVerify={authVerify}
            //   /> 
            // : authVerify === true
            // ?
            <NightSimulator
              power={power.toLowerCase().replace(/\s+/gm,'')}
              cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
              axis={axis}
              add={add}
              bcva={bcva}
              ns={ns}

              biometer={biometer}
              biometerAstig={biometerAstig}
              biometerAxis={biometerAxis}
              biometerStdDev={biometerStdDev}

              chordMuTotal={chordMuTotal}

              topographer={topographer}
              topographerAstig={topographerAstig}
              topographerAxis={topographerAxis}
              topographerSphAb={topographerSphAb}
              topographerHoa={topographerHoa}

              equipmentPreference={equipmentPreference}
              sphAbTarget={sphAbTarget}
              hoaMaxPreference={hoaMaxPreference}

              lensData={lensData}
              lensOption={lensOption}
              setLensOption={setLensOption}
              choseBestLens={choseBestLens}
              setChoseBestLens={setChoseBestLens}

              lensName={lensName}
              setLensName={setLensName}
              lensModel={lensModel}
              setLensModel={setLensModel}
              lensLocation={lensLocation}
              setLensLocation={setLensLocation}
              lensHospitalFee={lensHospitalFee}
              setLensHospitalFee={setLensHospitalFee}
              lensPhysicianFee={lensPhysicianFee}
              setLensPhysicianFee={setLensPhysicianFee}
              lensSphAb={lensSphAb}
              setLensSphAb={setLensSphAb}
              lensKSphAbResidual={lensKSphAbResidual}
              setLensKSphAbResidual={setLensKSphAbResidual}
              lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
              setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
              lensCorrectsNearVision={lensCorrectsNearVision}
              setLensCorrectsNearVision={setLensCorrectsNearVision}
              lensGlare={lensGlare}
              setLensGlare={setLensGlare}
              lensTopographerHoaCutoff={lensTopographerHoaCutoff}
              setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
              lensKAstigMax={lensKAstigMax}
              setLensKAstigMax={setLensKAstigMax}
              lensKAstigMin={lensKAstigMin}
              setLensKAstigMin={setLensKAstigMin}
              lensKAstigResidual={lensKAstigResidual}
              setLensKAstigResidual={setLensKAstigResidual}
              lensChordMu={lensChordMu}
              setLensChordMu={setLensChordMu}
              lensBiometerStdDev={lensBiometerStdDev}
              setLensBiometerStdDev={setLensBiometerStdDev}
              lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
              setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
              lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
              setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
              setLensConsentId={setLensConsentId}

              contraindications={contraindications}
              lensTargetNear={lensTargetNear}
              setLensTargetNear={setLensTargetNear}

              lensCriFee={lensCriFee}
              setLensCriFee={setLensCriFee}
              lensFlacsFee={lensFlacsFee}
              setLensFlacsFee={setLensFlacsFee}

              criOffer={criOffer}
              lensCriMinAstig={lensCriMinAstig}
              setLensCriMinAstig={setLensCriMinAstig}
              lensCriMaxAstig={lensCriMaxAstig}
              setLensCriMaxAstig={setLensCriMaxAstig}
              lensCriRequire={lensCriRequire}
              setLensCriRequire={setLensCriRequire}
              lensFlacsRequire={lensFlacsRequire}
              setLensFlacsRequire={setLensFlacsRequire}
              cri={cri}
              setCri={setCri}
              criAlwaysOn={criAlwaysOn}
              setCriAlwaysOn={setCriAlwaysOn}
              criAllowToggle={criAllowToggle}
              setCriAllowToggle={setCriAllowToggle}
              criBioTopoAstigMax={criBioTopoAstigMax}
              criBioTopoAxisMax={criBioTopoAxisMax}

              flacs={flacs}
              setFlacs={setFlacs}
              flacsOffer={flacsOffer}
              setFlacsOffer={setFlacsOffer}
              flacsAlwaysOn={flacsAlwaysOn}
              setFlacsAlwaysOn={setFlacsAlwaysOn}
              flacsAllowToggle={flacsAllowToggle}
              setFlacsAllowToggle={setFlacsAllowToggle}

              encryptionKey={encryptionKey}
              setEncryptionKey={setEncryptionKey}
              cipherText={cipherText}
              setCipherText={setCipherText}
              qrToken={qrToken}
              setQrToken={setQrToken}


              physicianName={physicianName}

              oopTotal={oopTotal}
              setOopTotal={setOopTotal}
              oopPhysician={oopPhysician}
              setOopPhysician={setOopPhysician}
              oopHospital={oopHospital}
              setOopHospital={setOopHospital}

              setPtLensList={setPtLensList}

              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              onlyCompatible={onlyCompatible}
              setOnlyCompatible={setOnlyCompatible}

              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}
            />
          // : null
          }
        />


        <Route 
          path="/paperwork" 
          element={
            // authVerify === false 
            // ? <LandingPage
            //     userType={userType}
            //     setUserType={setUserType}
            //     authVerify={authVerify}
            //   /> 
            // : authVerify === true
            // ?
            <Paperwork
              power={power.toLowerCase().replace(/\s+/gm,'')}
              cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
              axis={axis}
              add={add}
              codeStatus={codeStatus}
              allergies={allergies}
              side={side}
              bcva={bcva}
              ns={ns}
              bat={bat}
              biometer={biometer}
              biometerAstig={biometerAstig}
              biometerAxis={biometerAxis}
              chordMuX={chordMuX}
              chordMuY={chordMuY}
              chordMuTotal={chordMuTotal}
              topographer={topographer}
              topographerAstig={topographerAstig}
              topographerAxis={topographerAxis}
              topographerSphAb={topographerSphAb}
              topographerHoa={topographerHoa}
              lensOption={lensOption}
              lensModel={lensModel}
              lensLocation={lensLocation}
              sulcusLens={sulcusLens}
              lensHospitalFee={lensHospitalFee}
              lensPhysicianFee={lensPhysicianFee}
              lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
              lensCorrectsNearVision={lensCorrectsNearVision}
              lensTargetNear={lensTargetNear}
              lensKAstigMin={lensKAstigMin}
              lensConsentId={lensConsentId}
              consentData={consentData}
              equipmentPreference={equipmentPreference}
              cri={cri}
              lensCriRequire={lensCriRequire}
              criAllowToggle={criAllowToggle}
              criConsentId={criConsentId}
              flacsOffer={flacsOffer}
              flacs={flacs}
              oopTotal={oopTotal}
              oopPhysician={oopPhysician}
              oopHospital={oopHospital}
              lensFlacsRequire={lensFlacsRequire}
              flacsConsentId={flacsConsentId}
              flacsAllowToggle={flacsAllowToggle}
              lensCriFee={lensCriFee}
              lensFlacsFee={lensFlacsFee}
              contraindications={contraindications}
              physicianName={physicianName}
              clinicName={clinicName}
              clinicStreet={clinicStreet}
              clinicCity={clinicCity}
              clinicState={clinicState}
              clinicZip={clinicZip}
              clinicPhone={clinicPhone}
              clinicFax={clinicFax}

              bedsheetData={bedsheetData}
              bedsheetId={bedsheetId}
              bedsheetUse={bedsheetUse}
              bedsheetName={bedsheetName}

              cataractAddOn={cataractAddOn}
              setCataractAddOn={setCataractAddOn}

              q1={q1}
              q2={q2}
              q3={q3}
              q4={q4}
              q5={q5}
              q6={q6}
              q7={q7}
              q8={q8}
              q9={q9}
              q10={q10}
              q11={q11}
              q12={q12}
              q13={q13}
              q14={q14}

              ptLensList={ptLensList}

              whyLens={whyLens}

              userType={userType}
              useTutorial={useTutorial}
              useVideo={useVideo}

              linkTo='qr'
              qrToken={qrToken}
              cipherText={cipherText}
              encryptionKey={encryptionKey}
            />
          // : null
          }
        />


        <Route 
          path='/summary' 
          element={
            <Summary
              userType={userType}
              lensOption={lensOption}
              lensTargetNear={lensTargetNear}
          />}
        />



        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={
          // <UserNew/>
          <About/>
          }/>
        

        <Route path='/myaccount' element={
          
          authVerify === false 
          ? <LandingPage
              userType={userType}
              setUserType={setUserType}
              authVerify={authVerify}
            /> 
          :  authVerify === true
          ? <MyAccount/>
          : null
        }/>

        <Route 
          path='/lenses' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            : authVerify === true
            ?
            <LensList
              criOffer={criOffer}
              topographer={topographer}
              lensData={lensData}
              flacsOffer={flacsOffer}
              contraindicationsList={contraindicationsList}
              staticLensListOptions={staticLensListOptions}
            />
            : null
          }
        />

        <Route path='/equipment' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <BiometerList/>
            : null
          }
        />

        <Route 
          path='/cri' 
          element={ 
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            : authVerify === true
            ? <CriFlacs/>
            : null
          }
        />

        <Route 
          path='/education' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            : authVerify === true
            ? <EducationList
                useTutorial={useTutorial}
                setUseTutorial={setUseTutorial}
                useVideo={useVideo}
                setUseVideo={setUseVideo}
                educationPost={educationPost}
                videoData={videoData}
              />
            : null 
          }
        />

        <Route 
          path='/consents' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            : authVerify === true
            ? <ConsentList
                consentData={consentData}
                side={side}
              />
            : null
          }
        />
       
        <Route 
          path='/facesheet' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            : authVerify === true
            ?
            <BedsheetEdit
              bedsheetData={bedsheetData}
              bedsheetId={bedsheetId}
              bedsheetUse={bedsheetUse}
              bedsheetName={bedsheetName}
              side={side}
            />
            : null
          }
        />

        <Route 
          path='/user' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <UserEdit/>
            : null
          }
        />

        
        <Route path='/useraccess' element={  //UserAccess is how you change the password
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <UserAccess/>
            : null
          }
        />
        
        
        <Route path='/analytics' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <Analytics/>
            : null
          }
        />
        

        <Route 
          path='/paymentsuccess/:id' 
          element={
            <PaymentSuccess 
              paymentQr={paymentQr}
              setPaymentQr={setPaymentQr}
              handleLoginSuccess={handleLoginSuccess}
            />
          } 
        />
        
        <Route 
          path='/qrpurchased/:id' 
          element={
            <QrPurchased 
              paymentQr={paymentQr}
              setPaymentQr={setPaymentQr}
              handleLoginSuccess={handleLoginSuccess}
            />
          } 
        />
        
        <Route 
          path='/extrasession/:id' 
          element={
            <ExtraSession 
              paymentQr={paymentQr}
              setPaymentQr={setPaymentQr}
              handleLoginSuccess={handleLoginSuccess}
            />
          } 
        />

        <Route path='/premiumaccess' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <PremiumAccess/>
            : null
          }
        />
       
        <Route path='/patientsimulator' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <PatientSimulator
                biometerAstig={staticBiometerAstig}
                setBiometerAstig={setStaticBiometerAstig}
                topographerAstig={staticTopographerAstig}
                setTopographerAstig={setStaticTopographerAstig}
                basicLensListOptions={basicLensListOptions}
                haveAstig={haveAstig}
                setHaveAstig={setHaveAstig}

                biometer={biometer}
                setBiometer={setBiometer}
                biometerStdDev={biometerStdDev}

                chordMuTotal={chordMuTotal}

                topographer={topographer}
                setTopographer={setTopographer}

                equipmentPreference={equipmentPreference}
                setEquipmentPreference={setEquipmentPreference}
                sphAbTarget={sphAbTarget}
                setSphAbTarget={setSphAbTarget}
                hoaMaxPreference={hoaMaxPreference}
                setHoaMaxPreference={setHoaMaxPreference}

                basicLensData={basicLensData}
                setBasicLensData={setBasicLensData}
                basicLensOption={basicLensOption}
                setBasicLensOption={setBasicLensOption}
                choseBestLens={choseBestLens}
                setChoseBestLens={setChoseBestLens}

                lensName={lensName}
                setLensName={setLensName}
                lensModel={lensModel}
                setLensModel={setLensModel}
                lensLocation={lensLocation}
                setLensLocation={setLensLocation}
                lensHospitalFee={lensHospitalFee}
                setLensHospitalFee={setLensHospitalFee}
                lensPhysicianFee={lensPhysicianFee}
                setLensPhysicianFee={setLensPhysicianFee}
                lensSphAb={lensSphAb}
                setLensSphAb={setLensSphAb}
                lensKSphAbResidual={lensKSphAbResidual}
                setLensKSphAbResidual={setLensKSphAbResidual}
                lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
                setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
                lensCorrectsNearVision={lensCorrectsNearVision}
                setLensCorrectsNearVision={setLensCorrectsNearVision}
                lensGlare={lensGlare}
                setLensGlare={setLensGlare}
                lensTopographerHoaCutoff={lensTopographerHoaCutoff}
                setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
                lensKAstigMax={lensKAstigMax}
                setLensKAstigMax={setLensKAstigMax}
                lensKAstigMin={lensKAstigMin}
                setLensKAstigMin={setLensKAstigMin}
                lensKAstigResidual={lensKAstigResidual}
                setLensKAstigResidual={setLensKAstigResidual}
                lensChordMu={lensChordMu}
                setLensChordMu={setLensChordMu}
                lensBiometerStdDev={lensBiometerStdDev}
                setLensBiometerStdDev={setLensBiometerStdDev}
                lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
                setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
                setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
                setLensConsentId={setLensConsentId}

                contraindications={contraindications}
                lensTargetNear={lensTargetNear}
                setLensTargetNear={setLensTargetNear}

                setPtLensList={setPtLensList}

              />
            : null
          }
        />
        
        <Route path='/patientnightsimulator' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <PatientNightSimulator
                biometerAstig={staticBiometerAstig}
                setBiometerAstig={setStaticBiometerAstig}
                topographerAstig={staticTopographerAstig}
                setTopographerAstig={setStaticTopographerAstig}
                basicLensListOptions={basicLensListOptions}
                haveAstig={haveAstig}
                setHaveAstig={setHaveAstig}


                biometer={biometer}
                setBiometer={setBiometer}
                biometerStdDev={biometerStdDev}

                chordMuTotal={chordMuTotal}

                topographer={topographer}
                setTopographer={setTopographer}

                equipmentPreference={equipmentPreference}
                setEquipmentPreference={setEquipmentPreference}
                sphAbTarget={sphAbTarget}
                setSphAbTarget={setSphAbTarget}
                hoaMaxPreference={hoaMaxPreference}
                setHoaMaxPreference={setHoaMaxPreference}

                basicLensData={basicLensData}
                setBasicLensData={setBasicLensData}
                basicLensOption={basicLensOption}
                setBasicLensOption={setBasicLensOption}
                choseBestLens={choseBestLens}
                setChoseBestLens={setChoseBestLens}

                lensName={lensName}
                setLensName={setLensName}
                lensModel={lensModel}
                setLensModel={setLensModel}
                lensLocation={lensLocation}
                setLensLocation={setLensLocation}
                lensHospitalFee={lensHospitalFee}
                setLensHospitalFee={setLensHospitalFee}
                lensPhysicianFee={lensPhysicianFee}
                setLensPhysicianFee={setLensPhysicianFee}
                lensSphAb={lensSphAb}
                setLensSphAb={setLensSphAb}
                lensKSphAbResidual={lensKSphAbResidual}
                setLensKSphAbResidual={setLensKSphAbResidual}
                lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
                setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
                lensCorrectsNearVision={lensCorrectsNearVision}
                setLensCorrectsNearVision={setLensCorrectsNearVision}
                lensGlare={lensGlare}
                setLensGlare={setLensGlare}
                lensTopographerHoaCutoff={lensTopographerHoaCutoff}
                setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
                lensKAstigMax={lensKAstigMax}
                setLensKAstigMax={setLensKAstigMax}
                lensKAstigMin={lensKAstigMin}
                setLensKAstigMin={setLensKAstigMin}
                lensKAstigResidual={lensKAstigResidual}
                setLensKAstigResidual={setLensKAstigResidual}
                lensChordMu={lensChordMu}
                setLensChordMu={setLensChordMu}
                lensBiometerStdDev={lensBiometerStdDev}
                setLensBiometerStdDev={setLensBiometerStdDev}
                lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
                setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
                setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
                setLensConsentId={setLensConsentId}

                contraindications={contraindications}
                lensTargetNear={lensTargetNear}
                setLensTargetNear={setLensTargetNear}

                setPtLensList={setPtLensList}

              />
            : null
          }
        />
        


      <Route 
        path='/lensguide' 
        element={
          authVerify === false 
          ? <LandingPage
              userType={userType}
              setUserType={setUserType}
              authVerify={authVerify}
            /> 
          : authVerify === true
          ?
            <LensGuide
              basicLensListOptions={basicLensListOptions}
              basicLensOption={basicLensOption}
              lensTargetNear={lensTargetNear}
            />
          : null
        }
      />

      <Route path='/advancedmode' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <AdvancedMode/>
            : null
          }
        />

        <Route path='/eyeinfo' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? 
              <EyeInfo
                side={side}
                setSide={setSide}
                lensName={lensName}
                setLensName={setLensName}
                plannedLens={plannedLens}
                setPlannedLens={setPlannedLens}
                lensModel={lensModel}
                setLensModel={setLensModel}
                staticLensListOptions={staticLensListOptions}
                power={power}
                setPower={setPower}
                cylinder={cylinder}
                setCylinder={setCylinder}
                axis={axis}
                setAxis={setAxis}
                add={add}
                setAdd={setAdd}
                codeStatus={codeStatus}
                setCodeStatus={setCodeStatus}
                allergies={allergies}
                setAllergies={setAllergies}
                bcva={bcva}
                setBcva={setBcva}
                ns={ns}
                setNs={setNs}
                bat={bat}
                setBat={setBat}
                biometer={biometer}
                biometerAstig={biometerAstig}
                setBiometerAstig={setBiometerAstig}
                biometerAxis={biometerAxis}
                setBiometerAxis={setBiometerAxis}
                biometerStdDev={biometerStdDev}
                setBiometerStdDev={setBiometerStdDev}
                chordMuX={chordMuX}
                setChordMuX={setChordMuX}
                chordMuY={chordMuY}
                setChordMuY={setChordMuY}
                chordMuTotal={chordMuTotal}
                setChordMuTotal={setChordMuTotal}
                topographer={topographer}
                topographerAstig={topographerAstig}
                setTopographerAstig={setTopographerAstig}
                topographerAxis={topographerAxis}
                setTopographerAxis={setTopographerAxis}
                topographerSphAb={topographerSphAb}
                setTopographerSphAb={setTopographerSphAb}
                topographerHoa={topographerHoa}
                setTopographerHoa={setTopographerHoa}
                comaH={comaH}
                setComaH={setComaH}
                comaV={comaV}
                setComaV={setComaV}
                lensOption={lensOption}
                setLensOption={setLensOption}
                contraindications={contraindications}
                setContraindications={setContraindications}
                physicianName={physicianName}
                setUserType={setUserType}

                cataractAddOn={cataractAddOn}
                setCataractAddOn={setCataractAddOn}

                paymentQr={paymentQr}
              />
            : null
          }
        />
        
        
        <Route path='/premiumsimulator' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <PremiumSimulator
                power={power.toLowerCase().replace(/\s+/gm,'')}
                cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
                axis={axis}
                add={add}
                bcva={bcva}
                ns={ns}

                biometer={biometer}
                setBiometer={setBiometer}
                biometerAstig={biometerAstig}
                biometerAxis={biometerAxis}
                biometerStdDev={biometerStdDev}

                chordMuTotal={chordMuTotal}

                topographer={topographer}
                setTopographer={setTopographer}
                topographerAstig={topographerAstig}
                topographerAxis={topographerAxis}
                topographerSphAb={topographerSphAb}
                topographerHoa={topographerHoa}

                equipmentPreference={equipmentPreference}
                setEquipmentPreference={setEquipmentPreference}
                sphAbTarget={sphAbTarget}
                setSphAbTarget={setSphAbTarget}
                hoaMaxPreference={hoaMaxPreference}
                setHoaMaxPreference={setHoaMaxPreference}

                lensData={lensData}
                setLensData={setLensData}
                lensOption={lensOption}
                setLensOption={setLensOption}
                choseBestLens={choseBestLens}
                setChoseBestLens={setChoseBestLens}

                lensName={lensName}
                setLensName={setLensName}
                lensModel={lensModel}
                setLensModel={setLensModel}
                lensLocation={lensLocation}
                setLensLocation={setLensLocation}
                lensHospitalFee={lensHospitalFee}
                setLensHospitalFee={setLensHospitalFee}
                lensPhysicianFee={lensPhysicianFee}
                setLensPhysicianFee={setLensPhysicianFee}
                lensSphAb={lensSphAb}
                setLensSphAb={setLensSphAb}
                lensKSphAbResidual={lensKSphAbResidual}
                setLensKSphAbResidual={setLensKSphAbResidual}
                lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
                setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
                lensCorrectsNearVision={lensCorrectsNearVision}
                setLensCorrectsNearVision={setLensCorrectsNearVision}
                lensGlare={lensGlare}
                setLensGlare={setLensGlare}
                lensTopographerHoaCutoff={lensTopographerHoaCutoff}
                setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
                lensKAstigMax={lensKAstigMax}
                setLensKAstigMax={setLensKAstigMax}
                lensKAstigMin={lensKAstigMin}
                setLensKAstigMin={setLensKAstigMin}
                lensKAstigResidual={lensKAstigResidual}
                setLensKAstigResidual={setLensKAstigResidual}
                lensChordMu={lensChordMu}
                setLensChordMu={setLensChordMu}
                lensBiometerStdDev={lensBiometerStdDev}
                setLensBiometerStdDev={setLensBiometerStdDev}
                lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
                setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
                setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
                setLensConsentId={setLensConsentId}

                contraindications={contraindications}
                lensTargetNear={lensTargetNear}
                setLensTargetNear={setLensTargetNear}

                lensCriFee={lensCriFee}
                setLensCriFee={setLensCriFee}
                lensFlacsFee={lensFlacsFee}
                setLensFlacsFee={setLensFlacsFee}

                criOffer={criOffer}
                setCriOffer={setCriOffer}
                lensCriMinAstig={lensCriMinAstig}
                setLensCriMinAstig={setLensCriMinAstig}
                lensCriMaxAstig={lensCriMaxAstig}
                setLensCriMaxAstig={setLensCriMaxAstig}
                lensCriRequire={lensCriRequire}
                setLensCriRequire={setLensCriRequire}
                lensFlacsRequire={lensFlacsRequire}
                setLensFlacsRequire={setLensFlacsRequire}
                cri={cri}
                setCri={setCri}
                criAlwaysOn={criAlwaysOn}
                setCriAlwaysOn={setCriAlwaysOn}
                criAllowToggle={criAllowToggle}
                setCriAllowToggle={setCriAllowToggle}
                criBioTopoAstigMax={criBioTopoAstigMax}
                setCriBioTopoAstigMax={setCriBioTopoAstigMax}
                criBioTopoAxisMax={criBioTopoAxisMax}
                setCriBioTopoAxisMax={setCriBioTopoAxisMax}

                flacs={flacs}
                setFlacs={setFlacs}
                flacsOffer={flacsOffer}
                setFlacsOffer={setFlacsOffer}
                flacsAlwaysOn={flacsAlwaysOn}
                setFlacsAlwaysOn={setFlacsAlwaysOn}
                flacsAllowToggle={flacsAllowToggle}
                setFlacsAllowToggle={setFlacsAllowToggle}

                physicianName={physicianName}

                oopTotal={oopTotal}
                setOopTotal={setOopTotal}
                oopPhysician={oopPhysician}
                setOopPhysician={setOopPhysician}
                oopHospital={oopHospital}
                setOopHospital={setOopHospital}

                setPtLensList={setPtLensList}

                userType={userType}
                useVideo={useVideo}
                useTutorial={useTutorial}

                staticLensListOptions={staticLensListOptions}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                onlyUpToDate={onlyUpToDate}
                setOnlyUpToDate={setOnlyUpToDate}
                onlyCompatible={onlyCompatible}
                setOnlyCompatible={setOnlyCompatible}
              />
            : null
          }
        />


        <Route path='/premiumnightsimulator' element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <PremiumNightSimulator
                power={power.toLowerCase().replace(/\s+/gm,'')}
                cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
                axis={axis}
                add={add}
                bcva={bcva}
                ns={ns}

                biometer={biometer}
                setBiometer={setBiometer}
                biometerAstig={biometerAstig}
                biometerAxis={biometerAxis}
                biometerStdDev={biometerStdDev}

                chordMuTotal={chordMuTotal}

                topographer={topographer}
                setTopographer={setTopographer}
                topographerAstig={topographerAstig}
                topographerAxis={topographerAxis}
                topographerSphAb={topographerSphAb}
                topographerHoa={topographerHoa}

                equipmentPreference={equipmentPreference}
                setEquipmentPreference={setEquipmentPreference}
                sphAbTarget={sphAbTarget}
                setSphAbTarget={setSphAbTarget}
                hoaMaxPreference={hoaMaxPreference}
                setHoaMaxPreference={setHoaMaxPreference}

                lensData={lensData}
                setLensData={setLensData}
                lensOption={lensOption}
                setLensOption={setLensOption}
                choseBestLens={choseBestLens}
                setChoseBestLens={setChoseBestLens}

                lensName={lensName}
                setLensName={setLensName}
                lensModel={lensModel}
                setLensModel={setLensModel}
                lensLocation={lensLocation}
                setLensLocation={setLensLocation}
                lensHospitalFee={lensHospitalFee}
                setLensHospitalFee={setLensHospitalFee}
                lensPhysicianFee={lensPhysicianFee}
                setLensPhysicianFee={setLensPhysicianFee}
                lensSphAb={lensSphAb}
                setLensSphAb={setLensSphAb}
                lensKSphAbResidual={lensKSphAbResidual}
                setLensKSphAbResidual={setLensKSphAbResidual}
                lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
                setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
                lensCorrectsNearVision={lensCorrectsNearVision}
                setLensCorrectsNearVision={setLensCorrectsNearVision}
                lensGlare={lensGlare}
                setLensGlare={setLensGlare}
                lensTopographerHoaCutoff={lensTopographerHoaCutoff}
                setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
                lensKAstigMax={lensKAstigMax}
                setLensKAstigMax={setLensKAstigMax}
                lensKAstigMin={lensKAstigMin}
                setLensKAstigMin={setLensKAstigMin}
                lensKAstigResidual={lensKAstigResidual}
                setLensKAstigResidual={setLensKAstigResidual}
                lensChordMu={lensChordMu}
                setLensChordMu={setLensChordMu}
                lensBiometerStdDev={lensBiometerStdDev}
                setLensBiometerStdDev={setLensBiometerStdDev}
                lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
                setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
                setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
                setLensConsentId={setLensConsentId}

                contraindications={contraindications}
                lensTargetNear={lensTargetNear}
                setLensTargetNear={setLensTargetNear}

                lensCriFee={lensCriFee}
                setLensCriFee={setLensCriFee}
                lensFlacsFee={lensFlacsFee}
                setLensFlacsFee={setLensFlacsFee}

                criOffer={criOffer}
                setCriOffer={setCriOffer}
                lensCriMinAstig={lensCriMinAstig}
                setLensCriMinAstig={setLensCriMinAstig}
                lensCriMaxAstig={lensCriMaxAstig}
                setLensCriMaxAstig={setLensCriMaxAstig}
                lensCriRequire={lensCriRequire}
                setLensCriRequire={setLensCriRequire}
                lensFlacsRequire={lensFlacsRequire}
                setLensFlacsRequire={setLensFlacsRequire}
                cri={cri}
                setCri={setCri}
                criAlwaysOn={criAlwaysOn}
                setCriAlwaysOn={setCriAlwaysOn}
                criAllowToggle={criAllowToggle}
                setCriAllowToggle={setCriAllowToggle}
                criBioTopoAstigMax={criBioTopoAstigMax}
                setCriBioTopoAstigMax={setCriBioTopoAstigMax}
                criBioTopoAxisMax={criBioTopoAxisMax}
                setCriBioTopoAxisMax={setCriBioTopoAxisMax}

                flacs={flacs}
                setFlacs={setFlacs}
                flacsOffer={flacsOffer}
                setFlacsOffer={setFlacsOffer}
                flacsAlwaysOn={flacsAlwaysOn}
                setFlacsAlwaysOn={setFlacsAlwaysOn}
                flacsAllowToggle={flacsAllowToggle}
                setFlacsAllowToggle={setFlacsAllowToggle}

                physicianName={physicianName}

                oopTotal={oopTotal}
                setOopTotal={setOopTotal}
                oopPhysician={oopPhysician}
                setOopPhysician={setOopPhysician}
                oopHospital={oopHospital}
                setOopHospital={setOopHospital}

                setPtLensList={setPtLensList}

                userType={userType}
                useVideo={useVideo}
                useTutorial={useTutorial}

                staticLensListOptions={staticLensListOptions}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                onlyUpToDate={onlyUpToDate}
                setOnlyUpToDate={setOnlyUpToDate}
                onlyCompatible={onlyCompatible}
                setOnlyCompatible={setOnlyCompatible}
              />
            : null
          }
        />

        <Route 
          path='/lensguideadvanced' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            : authVerify === true
            ?
              <LensGuideAdvanced
                staticLensListOptions={staticLensListOptions}
                lensOption={lensOption}
                lensData={lensData}
                setLensOption={setLensOption}
                lensTargetNear={lensTargetNear}
                cri={cri}
              />
            : null
          }
        />
        
        <Route 
          path='/comparelenses' 
          element={
            authVerify === false 
            ? <LandingPage
                userType={userType}
                setUserType={setUserType}
                authVerify={authVerify}
              /> 
            :  authVerify === true
            ? <CompareLenses
                power={power.toLowerCase().replace(/\s+/gm,'')}
                cylinder={cylinder.toLowerCase().replace(/\s+/gm,'')}
                axis={axis}
                add={add}
                bcva={bcva}
                ns={ns}

                biometer={biometer}
                setBiometer={setBiometer}
                biometerAstig={biometerAstig}
                biometerAxis={biometerAxis}
                biometerStdDev={biometerStdDev}

                chordMuTotal={chordMuTotal}

                topographer={topographer}
                setTopographer={setTopographer}
                topographerAstig={topographerAstig}
                topographerAxis={topographerAxis}
                topographerSphAb={topographerSphAb}
                topographerHoa={topographerHoa}

                equipmentPreference={equipmentPreference}
                setEquipmentPreference={setEquipmentPreference}
                sphAbTarget={sphAbTarget}
                setSphAbTarget={setSphAbTarget}
                hoaMaxPreference={hoaMaxPreference}
                setHoaMaxPreference={setHoaMaxPreference}

                lensData={lensData}
                setLensData={setLensData}
                lensOption={lensOption}
                setLensOption={setLensOption}
                lensOptionTwo={lensOptionTwo}
                setLensOptionTwo={setLensOptionTwo}
                choseBestLens={choseBestLens}
                setChoseBestLens={setChoseBestLens}

                lensName={lensName}
                setLensName={setLensName}
                lensModel={lensModel}
                setLensModel={setLensModel}
                lensLocation={lensLocation}
                setLensLocation={setLensLocation}
                lensHospitalFee={lensHospitalFee}
                setLensHospitalFee={setLensHospitalFee}
                lensPhysicianFee={lensPhysicianFee}
                setLensPhysicianFee={setLensPhysicianFee}
                lensSphAb={lensSphAb}
                setLensSphAb={setLensSphAb}
                lensKSphAbResidual={lensKSphAbResidual}
                setLensKSphAbResidual={setLensKSphAbResidual}
                lensCorrectsIntermediateVision={lensCorrectsIntermediateVision}
                setLensCorrectsIntermediateVision={setLensCorrectsIntermediateVision}
                lensCorrectsNearVision={lensCorrectsNearVision}
                setLensCorrectsNearVision={setLensCorrectsNearVision}
                lensGlare={lensGlare}
                setLensGlare={setLensGlare}
                lensTopographerHoaCutoff={lensTopographerHoaCutoff}
                setLensTopographerHoaCutoff={setLensTopographerHoaCutoff}
                lensKAstigMax={lensKAstigMax}
                setLensKAstigMax={setLensKAstigMax}
                lensKAstigMin={lensKAstigMin}
                setLensKAstigMin={setLensKAstigMin}
                lensKAstigResidual={lensKAstigResidual}
                setLensKAstigResidual={setLensKAstigResidual}
                lensChordMu={lensChordMu}
                setLensChordMu={setLensChordMu}
                lensBiometerStdDev={lensBiometerStdDev}
                setLensBiometerStdDev={setLensBiometerStdDev}
                lensBiometerTopographerAstigCutoff={lensBiometerTopographerAstigCutoff}
                setLensBiometerTopographerAstigCutoff={setLensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={lensBiometerTopographerAxisCutoff}
                setLensBiometerTopographerAxisCutoff={setLensBiometerTopographerAxisCutoff}
                setLensConsentId={setLensConsentId}

                contraindications={contraindications}
                lensTargetNear={lensTargetNear}
                setLensTargetNear={setLensTargetNear}

                lensCriFee={lensCriFee}
                setLensCriFee={setLensCriFee}
                lensFlacsFee={lensFlacsFee}
                setLensFlacsFee={setLensFlacsFee}

                criOffer={criOffer}
                setCriOffer={setCriOffer}
                lensCriMinAstig={lensCriMinAstig}
                setLensCriMinAstig={setLensCriMinAstig}
                lensCriMaxAstig={lensCriMaxAstig}
                setLensCriMaxAstig={setLensCriMaxAstig}
                lensCriRequire={lensCriRequire}
                setLensCriRequire={setLensCriRequire}
                lensFlacsRequire={lensFlacsRequire}
                setLensFlacsRequire={setLensFlacsRequire}
                cri={cri}
                setCri={setCri}
                criAlwaysOn={criAlwaysOn}
                setCriAlwaysOn={setCriAlwaysOn}
                criAllowToggle={criAllowToggle}
                setCriAllowToggle={setCriAllowToggle}
                criBioTopoAstigMax={criBioTopoAstigMax}
                setCriBioTopoAstigMax={setCriBioTopoAstigMax}
                criBioTopoAxisMax={criBioTopoAxisMax}
                setCriBioTopoAxisMax={setCriBioTopoAxisMax}

                flacs={flacs}
                setFlacs={setFlacs}
                flacsOffer={flacsOffer}
                setFlacsOffer={setFlacsOffer}
                flacsAlwaysOn={flacsAlwaysOn}
                setFlacsAlwaysOn={setFlacsAlwaysOn}
                flacsAllowToggle={flacsAllowToggle}
                setFlacsAllowToggle={setFlacsAllowToggle}

                physicianName={physicianName}

                oopTotal={oopTotal}
                setOopTotal={setOopTotal}
                oopPhysician={oopPhysician}
                setOopPhysician={setOopPhysician}
                oopHospital={oopHospital}
                setOopHospital={setOopHospital}

                setPtLensList={setPtLensList}

                userType={userType}
                useVideo={useVideo}
                useTutorial={useTutorial}

                staticLensListOptions={staticLensListOptions}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                onlyUpToDate={onlyUpToDate}
                setOnlyUpToDate={setOnlyUpToDate}
                onlyCompatible={onlyCompatible}
                setOnlyCompatible={setOnlyCompatible}
              />
            : null
          }
        />
        
        
        <Route path='*' element={<LandingPage/>}/>
        <Route path="/about" element={<About/>}/>
  


      </Routes>
    </Router>
  )
}




// ========================================================================

const contraindicationsList = [
  {
    name: 'Lasik or PRK',
    value: 'false',
  },
  {
    name: 'Radial Keratotomy',
    value: 'false',
  },
  {
    name: 'Keratoconus',
    value: 'false',
  },
  {
    name: 'Corneal Guttata',
    value: 'false',
  },
  {
    name: 'Epiretinal membrane',
    value: 'false',
  },
  {
    name: 'Diabetic retinopathy',
    value: 'false',
  },
  {
    name: 'Macular degeneration',
    value: 'false',
  },
  {
    name: 'Retinal detachment',
    value: 'false',
  },
  {
    name: 'Amblyopia',
    value: 'false',
  },
  {
    name: 'Pterygium',
    value: 'false',
  },
]

const presbyContraindicationsList = [
  {
    name: 'Lasik or PRK',
    value: 'false',
  },
  {
    name: 'Radial Keratotomy',
    value: 'true',
  },
  {
    name: 'Keratoconus',
    value: 'true',
  },
  {
    name: 'Corneal Guttata',
    value: 'true',
  },
  {
    name: 'Epiretinal membrane',
    value: 'true',
  },
  {
    name: 'Diabetic retinopathy',
    value: 'true',
  },
  {
    name: 'Macular degeneration',
    value: 'true',
  },
  {
    name: 'Retinal detachment',
    value: 'true',
  },
  {
    name: 'Amblyopia',
    value: 'true',
  },
  {
    name: 'Pterygium',
    value: 'true',
  },
]

const toricContraindicationsList = [
  {
    name: 'Lasik or PRK',
    value: 'false',
  },
  {
    name: 'Radial Keratotomy',
    value: 'true',
  },
  {
    name: 'Keratoconus',
    value: 'true',
  },
  {
    name: 'Corneal Guttata',
    value: 'true',
  },
  {
    name: 'Epiretinal membrane',
    value: 'false',
  },
  {
    name: 'Diabetic retinopathy',
    value: 'false',
  },
  {
    name: 'Macular degeneration',
    value: 'false',
  },
  {
    name: 'Retinal detachment',
    value: 'false',
  },
  {
    name: 'Amblyopia',
    value: 'true',
  },
  {
    name: 'Pterygium',
    value: 'true',
  },
]

const staticLensListOptions = [


  //Alcon lenses=========================================================
  {
    lensName: 'Clareon Panoptix (CCWTT0)',
    manufacturer: 'Alcon',
    lensModel: 'CCWTT0',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],//make a list of all categories that are betterLens and then the patient can add filters: e.g. reader with good tolerance is EDOF 
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Panoptix with HEV filter (CNWTT0)',
    manufacturer: 'Alcon',
    lensModel: 'CNWTT0',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ], //make a list of all categories that are betterLens and then the patient can add filters: e.g. reader with good tolerance is EDOF 
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Panoptix (TFAT00)',
    manufacturer: 'Alcon',
    lensModel: 'TFAT00',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ], //make a list of all categories that are betterLens and then the patient can add filters: e.g. reader with good tolerance is EDOF 
    moreRecent: 'CCWTT0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Panoptix with HEV filter (TFNT00)',
    manufacturer: 'Alcon',
    lensModel: 'TFNT00',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ], //make a list of all categories that are betterLens and then the patient can add filters: e.g. reader with good tolerance is EDOF 
    moreRecent: 'CNWTT0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Panoptix Toric (CCWTT_)',
    manufacturer: 'Alcon',
    lensModel: 'CCWTT___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Panoptix Toric with HEV filter (CNWTT_)',
    manufacturer: 'Alcon',
    lensModel: 'CNWTT___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Panoptix Toric (TFAT_)',
    manufacturer: 'Alcon',
    lensModel: 'TFAT___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCWTT___',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Panoptix Toric with HEV filter (TFNT_)',
    manufacturer: 'Alcon',
    lensModel: 'TFNT___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.10',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.6',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CNWTT___',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Vivity (CCWET0)',
    manufacturer: 'Alcon',
    lensModel: 'CCWET0',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',  
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Mild', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: 'Patients have reduced contrast sensitivity with this lens which some report as overall waxy vision',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Vivity with HEV filter (CNWET0)',
    manufacturer: 'Alcon',
    lensModel: 'CNWET0',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',  
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Mild', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: 'Patients have reduced contrast sensitivity with this lens which some report as overall waxy vision',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Vivity (DFT015)',
    manufacturer: 'Alcon',
    lensModel: 'DFT015',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',  
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '2020',
    lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Mild', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: 'Patients have reduced contrast sensitivity with this lens which some report as overall waxy vision',
      }, 
    ],
    moreRecent: 'CCWET0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Vivity toric (CCWET_)',
    manufacturer: 'Alcon',
    lensModel: 'CCWET___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Mild', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: 'Patients have reduced contrast sensitivity with this lens which some report as overall waxy vision',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Vivity toric with HEV filter (CNWET_)',
    manufacturer: 'Alcon',
    lensModel: 'CNWET___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Mild', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: 'Patients have reduced contrast sensitivity with this lens which some report as overall waxy vision',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Vivity toric (DFT_)',
    manufacturer: 'Alcon',
    lensModel: 'DFT______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Mild', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: 'Patients have reduced contrast sensitivity with this lens which some report as overall waxy vision',
      }, 
    ],
    moreRecent: 'CCWET___',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Toric (CCW0T_)',
    manufacturer: 'Alcon',
    lensModel: 'CCW0T___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'Clareon Toric with HEV filter (CNW0T_)',
    manufacturer: 'Alcon',
    lensModel: 'CNW0T___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'SA6AT_',
    manufacturer: 'Alcon',
    lensModel: 'SA6AT___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCW0T___',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'SN6AT_ with HEV filter',
    manufacturer: 'Alcon',
    lensModel: 'SN6AT___',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.20',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCW0T___',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'Clareon Monofocal AutonoMe (CCA0T0)',
    manufacturer: 'Alcon',
    lensModel: 'CCA0T0',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Monofocal AutonoMe with HEV filter (CNA0T0)',
    manufacturer: 'Alcon',
    lensModel: 'CNA0T0',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Monofocal with HEV filter (SY60WF)',
    manufacturer: 'Alcon',
    lensModel: 'SY60WF',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CNA0T0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Clareon Monofocal (CC60WF)',
    manufacturer: 'Alcon',
    lensModel: 'CC60WF',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCA0T0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'SN60WF with HEV filter',
    manufacturer: 'Alcon',
    lensModel: 'SN60WF',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CNA0T0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'SA60WF',
    manufacturer: 'Alcon',
    lensModel: 'SA60WF',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCA0T0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'SA60AT',
    manufacturer: 'Alcon',
    lensModel: 'SA60AT',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCA0T0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'SN60AT with HEV filter',
    manufacturer: 'Alcon',
    lensModel: 'SN60AT',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.20',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CNA0T0',  // if blank, this is the most recent lens
  },
  {
    lensName: 'MA60AC',
    manufacturer: 'Alcon',
    lensModel: 'MA60AC',
    lensLocation: 'Sulcus',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '0.15',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'MA60AC',  // if blank, this is the most recent lens
  },
  {
    lensName: 'MA60AT',
    manufacturer: 'Alcon',
    lensModel: 'MA60AT',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '0.14',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Common', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'CCA0T0',  // if blank, this is the most recent lens
  },



  // Johnson & Johnson Lenses================================================
  {
    lensName: 'Tecnis Symfony with Optiblue and Simplicity (DXR00V)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DXR00V',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'EDOF rings',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Symfony with Optiblue (ZXR00V)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZXR00V',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'EDOF rings',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DXR00V',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Symfony (ZXR00)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZXR00',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'EDOF rings',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DXR00V',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Symfony Toric with Optiblue and Simplicity (DXW_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DXW______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'EDOF rings',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Symfony Toric with Optiblue (ZXW_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZXW______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'EDOF rings',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DXW_',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Symfony Toric (ZXT_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZXT______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.99',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'No',
    lensGlare: 'EDOF rings',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DXW_',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Synergy with Optiblue and Simplicity (DFR00V)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DFR00V',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Synergy Toric with Optiblue and Simplicity (DFW_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DFW______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '2.5',
    lensKAstigMin: '1',
    lensKAstigResidual: '0.5',
    lensIntermediateVision: 'Yes',
    lensNearVision: 'Yes',
    lensGlare: 'Halo',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: '0.8',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: presbyContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '2500',
        glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
        halos: 'Some', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does', // 'Does' 'Does not'
        yellowing: 'Some', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Eyehance Monofocal with Simplicity (DIB00)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DIB00',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.26',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Monofocal with Simplicity (DCB00)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DCB00',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.26',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DIB00',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Monofocal (ZCB00)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZCB00',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '-0.26',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DIB00',  // if blank, this is the most recent lens
  },
  {
    lensName: 'Tecnis Eyehance toric with Simplicity (DIU_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'DIU______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'Tecnis Toric II (ZCU_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZCU______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DIU_',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'Tecnis Toric (ZCT_)',
    manufacturer: 'Johnson & Johnson',
    lensModel: 'ZCT______',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4',
    lensKAstigMin: '1',
    lensKAstigResidual: '0',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '-0.26',
    lensKSphAbResidual: '0.5',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'DIU_',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },



  // Bausch & Lomb lenses======================================================
  {
    lensName: 'EnVista (MX60E)',
    manufacturer: 'Bausch & Lomb',
    lensModel: 'MX60E',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '0',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'MX60PL',  // if blank, this is the most recent lens
  },
  {
    lensName: 'EnVista preloaded (MX60PL)',
    manufacturer: 'Bausch & Lomb',
    lensModel: 'MX60PL',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '0',
    lensKAstigMin: '0',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '0',
    lensHospitalFee: '0',
    lensSphAb: '0',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: 'dontuse',
      Atlas: 'dontuse'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: 'dontuse',
    lensBioTopoAxisMax: 'dontuse',
    lensCriMinAstig: '0.5',
    lensCriMaxAstig: '1',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: contraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '0',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
  },
  {
    lensName: 'EnVista Toric (MX60ET_)',
    manufacturer: 'Bausch & Lomb',
    lensModel: 'MX60ET_',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4.0',
    lensKAstigMin: '0.75',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '0',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Some', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: 'MX60PT_',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
  {
    lensName: 'EnVista Toric preloaded (MX60PT_)',
    manufacturer: 'Bausch & Lomb',
    lensModel: 'MX60PT_',
    lensLocation: 'Posterior chamber (typical)',
    lensKAstigMax: '4.0',
    lensKAstigMin: '0.75',
    lensKAstigResidual: 'dontuse',
    lensIntermediateVision: 'No',
    lensNearVision: 'No',
    lensGlare: 'Glare',
    lensPhysicianFee: '',
    lensHospitalFee: '',
    lensSphAb: '0',
    lensKSphAbResidual: 'dontuse',
    lensHoaMax: {
      Pentacam: '0.3',
      Atlas: '0.5'
    },
    lensChordMuMax: 'dontuse',
    lensBiometerStdDevMax: 'dontuse',
    lensBioTopoAstigMax: '0.5',
    lensBioTopoAxisMax: '15',
    lensCriMinAstig: '',
    lensCriMaxAstig: '',
    lensCriRequire: '',
    lensFlacsRequire: '',
    lensCriFee: '',
    lensFlacsFee: '',
    contraindications: toricContraindicationsList,
    lensSpecSheet: '',
    fda: '',
    lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
    prosCons: [
      {
        cost: '1500',
        glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
        halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
        blueblocker: 'Does not', // 'Does' 'Does not'
        yellowing: 'No', // 'Some' 'No'
        preloaded: 'Minimal', // 'Minimal' 'Some'
        glistenings: 'Unlikely', // 'Unlikely' 'Common'
        other: '',
      }, 
    ],
    moreRecent: '',  // if blank, this is the most recent lens
    cost: 'About $1500',
  },
]
  
  const basicLensListOptions = [


    //Basic lenses=========================================================
    {
      lensName: 'Trifocal',
      examples: ['Panoptix', 'Synergy'],
      manufacturer: 'Alcon',
      lensModel: 'Trifocal',
      lensLocation: 'Posterior chamber (typical)',
      lensKAstigMax: '0',
      lensKAstigMin: '0',
      lensKAstigResidual: '0.5',
      lensIntermediateVision: 'Yes',
      lensNearVision: 'Yes',
      lensGlare: 'Halo',
      lensPhysicianFee: '',
      lensHospitalFee: '',
      lensSphAb: '-0.10',
      lensKSphAbResidual: '0.5',
      lensHoaMax: {
        Pentacam: '0.3',
        Atlas: '0.5'
      },
      lensChordMuMax: '0.6',
      lensBiometerStdDevMax: 'dontuse',
      lensBioTopoAstigMax: '0.5',
      lensBioTopoAxisMax: '15',
      lensCriMinAstig: '0.5',
      lensCriMaxAstig: '1',
      lensCriRequire: '',
      lensFlacsRequire: '',
      lensCriFee: '',
      lensFlacsFee: '',
      contraindications: presbyContraindicationsList,
      lensSpecSheet: '',
      fda: '',
      lensType: 'trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
      prosCons: [
        {
          cost: '2500',
          glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
          halos: 'Some', // 'Minimal' 'Mild' 'Some'
          blueblocker: 'Does', // 'Does' 'Does not'
          yellowing: 'Some', // 'Some' 'No'
          preloaded: 'Minimal', // 'Minimal' 'Some'
          glistenings: 'Unlikely', // 'Unlikely' 'Common'
          other: '',
        }, 
      ], //make a list of all categories that are betterLens and then the patient can add filters: e.g. reader with good tolerance is EDOF 
      moreRecent: '',  // if blank, this is the most recent lens
      cost: 'About $2500',
    },
    {
      lensName: 'Trifocal Toric',
      examples: ['Panoptix Toric', 'Synergy Toric'],
      manufacturer: 'Trifocal Toric',
      lensModel: 'CCWTT___',
      lensLocation: 'Posterior chamber (typical)',
      lensKAstigMax: '2.5',
      lensKAstigMin: '1',
      lensKAstigResidual: '0.5',
      lensIntermediateVision: 'Yes',
      lensNearVision: 'Yes',
      lensGlare: 'Halo',
      lensPhysicianFee: '',
      lensHospitalFee: '',
      lensSphAb: '-0.10',
      lensKSphAbResidual: '0.5',
      lensHoaMax: {
        Pentacam: '0.3',
        Atlas: '0.5'
      },
      lensChordMuMax: '0.6',
      lensBiometerStdDevMax: 'dontuse',
      lensBioTopoAstigMax: '0.5',
      lensBioTopoAxisMax: '15',
      lensCriMinAstig: '',
      lensCriMaxAstig: '',
      lensCriRequire: '',
      lensFlacsRequire: '',
      lensCriFee: '',
      lensFlacsFee: '',
      contraindications: presbyContraindicationsList,
      lensSpecSheet: '',
      fda: '',
      lensType: 'toric trifocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
      prosCons: [
        {
          cost: '2500',
          glasses: 'Rare',  // 'Rare' 'Some' 'Frequent'
          halos: 'Some', // 'Minimal' 'Mild' 'Some'
          blueblocker: 'Does', // 'Does' 'Does not'
          yellowing: 'Some', // 'Some' 'No'
          preloaded: 'Minimal', // 'Minimal' 'Some'
          glistenings: 'Unlikely', // 'Unlikely' 'Common'
          other: '',
        }, 
      ],
      moreRecent: '',  // if blank, this is the most recent lens
      cost: 'About $2500',
    },
    {
      lensName: 'EDOF',
      examples: ['Symfony', 'Vivity'],
      manufacturer: 'Alcon',
      lensModel: 'EDOF',
      lensLocation: 'Posterior chamber (typical)',
      lensKAstigMax: '0',
      lensKAstigMin: '0',
      lensKAstigResidual: '0.99',
      lensIntermediateVision: 'Yes',
      lensNearVision: 'No',
      lensGlare: 'Halo',
      lensPhysicianFee: '',
      lensHospitalFee: '',
      lensSphAb: '-0.20',
      lensKSphAbResidual: '0.5',
      lensHoaMax: {
        Pentacam: '0.3',
        Atlas: '0.5'
      },
      lensChordMuMax: 'dontuse',
      lensBiometerStdDevMax: 'dontuse',
      lensBioTopoAstigMax: 'dontuse',
      lensBioTopoAxisMax: 'dontuse',
      lensCriMinAstig: '0.5',
      lensCriMaxAstig: '1',
      lensCriRequire: '',
      lensFlacsRequire: '',
      lensCriFee: '',  
      lensFlacsFee: '',
      contraindications: contraindicationsList,
      lensSpecSheet: '',
      fda: '',
      lensType: 'edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
      prosCons: [
        {
          cost: '2500',
          glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
          halos: 'Some', // 'Minimal' 'Mild' 'Some'
          blueblocker: 'Does', // 'Does' 'Does not'
          yellowing: 'Some', // 'Some' 'No'
          preloaded: 'Minimal', // 'Minimal' 'Some'
          glistenings: 'Unlikely', // 'Unlikely' 'Common'
          other: '',
        }, 
      ],
      moreRecent: '',  // if blank, this is the most recent lens
      cost: 'About $2500',
    },
    {
      lensName: 'EDOF Toric',
      examples: ['Symfony Toric', 'Vivity Toric'],
      manufacturer: 'Alcon',
      lensModel: 'EDOF Toric',
      lensLocation: 'Posterior chamber (typical)',
      lensKAstigMax: '2.5',
      lensKAstigMin: '1',
      lensKAstigResidual: '0.99',
      lensIntermediateVision: 'Yes',
      lensNearVision: 'No',
      lensGlare: 'Halo',
      lensPhysicianFee: '',
      lensHospitalFee: '',
      lensSphAb: '-0.20',
      lensKSphAbResidual: '0.5',
      lensHoaMax: {
        Pentacam: '0.3',
        Atlas: '0.5'
      },
      lensChordMuMax: 'dontuse',
      lensBiometerStdDevMax: 'dontuse',
      lensBioTopoAstigMax: '0.5',
      lensBioTopoAxisMax: '15',
      lensCriMinAstig: '',
      lensCriMaxAstig: '',
      lensCriRequire: '',
      lensFlacsRequire: '',
      lensCriFee: '',
      lensFlacsFee: '',
      contraindications: contraindicationsList,
      lensSpecSheet: '',
      fda: '',
      lensType: 'toric edof', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
      prosCons: [
        {
          cost: '2500',
          glasses: 'Some',  // 'Rare' 'Some' 'Frequent'
          halos: 'Some', // 'Minimal' 'Mild' 'Some'
          blueblocker: 'Does', // 'Does' 'Does not'
          yellowing: 'Some', // 'Some' 'No'
          preloaded: 'Minimal', // 'Minimal' 'Some'
          glistenings: 'Unlikely', // 'Unlikely' 'Common'
          other: '',
        }, 
      ],
      moreRecent: '',  // if blank, this is the most recent lens
      cost: 'About $2500',
    },
    {
      lensName: 'Toric',
      examples: ['Eyehance Toric', 'Clareon Toric'],
      manufacturer: 'Alcon',
      lensModel: 'Toric',
      lensLocation: 'Posterior chamber (typical)',
      lensKAstigMax: '4',
      lensKAstigMin: '1',
      lensKAstigResidual: '0',
      lensIntermediateVision: 'No',
      lensNearVision: 'No',
      lensGlare: 'Glare',
      lensPhysicianFee: '',
      lensHospitalFee: '',
      lensSphAb: '-0.20',
      lensKSphAbResidual: '0.5',
      lensHoaMax: {
        Pentacam: '0.3',
        Atlas: '0.5'
      },
      lensChordMuMax: 'dontuse',
      lensBiometerStdDevMax: 'dontuse',
      lensBioTopoAstigMax: '0.5',
      lensBioTopoAxisMax: '15',
      lensCriMinAstig: '',
      lensCriMaxAstig: '',
      lensCriRequire: '',
      lensFlacsRequire: '',
      lensCriFee: '',
      lensFlacsFee: '',
      contraindications: contraindicationsList,
      lensSpecSheet: '',
      fda: '',
      lensType: 'toric', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
      prosCons: [
        {
          cost: '1500',
          glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
          halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
          blueblocker: 'Does not', // 'Does' 'Does not'
          yellowing: 'No', // 'Some' 'No'
          preloaded: 'Some', // 'Minimal' 'Some'
          glistenings: 'Unlikely', // 'Unlikely' 'Common'
          other: '',
        }, 
      ],
      moreRecent: '',  // if blank, this is the most recent lens
      cost: 'About $1500',
    },
    {
      lensName: 'Monofocal',
      examples: ['Clareon Monofocal', 'Eyehance Monofocal'],
      manufacturer: 'Alcon',
      lensModel: 'Monofocal',
      lensLocation: 'Posterior chamber (typical)',
      lensKAstigMax: '0',
      lensKAstigMin: '0',
      lensKAstigResidual: 'dontuse',
      lensIntermediateVision: 'No',
      lensNearVision: 'No',
      lensGlare: 'Glare',
      lensPhysicianFee: '0',
      lensHospitalFee: '0',
      lensSphAb: '-0.20',
      lensKSphAbResidual: 'dontuse',
      lensHoaMax: {
        Pentacam: 'dontuse',
        Atlas: 'dontuse'
      },
      lensChordMuMax: 'dontuse',
      lensBiometerStdDevMax: 'dontuse',
      lensBioTopoAstigMax: 'dontuse',
      lensBioTopoAxisMax: 'dontuse',
      lensCriMinAstig: '0.5',
      lensCriMaxAstig: '1',
      lensCriRequire: '',
      lensFlacsRequire: '',
      lensCriFee: '',
      lensFlacsFee: '',
      contraindications: contraindicationsList,
      lensSpecSheet: '',
      fda: '',
      lensType: 'monofocal', //options are 'trifocal', 'toric trifocal', 'edof', 'toric edof', 'toric', 'monofocal'
      prosCons: [
        {
          cost: '0',
          glasses: 'Frequent',  // 'Rare' 'Some' 'Frequent'
          halos: 'Minimal', // 'Minimal' 'Mild' 'Some'
          blueblocker: 'Does not', // 'Does' 'Does not'
          yellowing: 'No', // 'Some' 'No'
          preloaded: 'Minimal', // 'Minimal' 'Some'
          glistenings: 'Unlikely', // 'Unlikely' 'Common'
          other: '',
        }, 
      ],
      moreRecent: '',  // if blank, this is the most recent lens
      cost: 'None',
    },
  ]



// Patient education video list
// [
//   {title: 'How the eye works', type: 'preop', order: '1', video: "https://www.youtube.com/embed/XuDZdE_XrQM?si=Epy68pcInuS_B9WC", start: '0', end: ''},
//   {title: 'What are cataracts?', type: 'preop', order: '', video: "https://www.youtube.com/embed/_gvdXfwYfhk?si=9mPsI-CR26B23Wgc", start: '0', end: ''},
//   {title: 'Cataract surgery', type: 'preop', order: '', video: "https://www.youtube.com/embed/LIza4BiEoOk?si=LHTcdtaf4AZ69OrW", start: '0', end: ''},
//   {title: 'Intraocular lenses', type: 'preop', order: '', video: "https://www.youtube.com/embed/G68gh_7IZaA?si=m2SgW58jKQ07zdQ3", start: '0', end: ''},
//   {title: 'Monofocal lenses', type: 'preop', order: '', video: "https://www.youtube.com/embed/Khbr-gMuj7c?si=xFjEO6NjiMqEtb7O", start: '0', end: ''},
//   {title: 'Astigmatism', type: 'preop', order: '', video: "https://www.youtube.com/embed/lDYEambEpOw?si=ZArZEiWG-R9bQBa1", start: '0', end: ''},
//   {title: 'Toric lenses', type: 'preop', order: '', video: "https://www.youtube.com/embed/QcXy1Trat1c?si=vltxUhuKQSCfgaEA", start: '0', end: ''},
//   {title: 'Presbyopia', type: 'preop', order: '', video: "https://www.youtube.com/embed/oLotpC9qmuU?si=LzpjQVmHa9Uz79JO", start: '0', end: ''},
//   {title: 'Trifocal lenses', type: 'preop', order: '', video: "https://www.youtube.com/embed/jEOf04mxsXs?si=uMFV-gwAkJQ4Too0", start: '0', end: ''},
//   {title: 'Intraocular lenses and cataract surgery', type: 'postop', order: '', video: "https://www.youtube.com/embed/Ui9yIzckiDg?si=nanQcfCEf6Smrgtk", start: '0', end: ''},
//   {title: 'Femtosecond laser assisted cataract surgery (FLACS)', type: 'preop', order: '', video: "https://www.youtube.com/embed/8zVKol3nKNo?si=pQhjKGAZzPoLzZ2H", start: '0', end: ''},   
// ]





// <Route path="/pdfupload" element={<PdfUpload/>}/>
// <Route path="/pdfread" element={<PdfRead/>}/>

// <Route 
// path='/lensquestions' 
// element={
//   // authVerify === false 
//   // ? <LandingPage
//   //     userType={userType}
//   //     setUserType={setUserType}
//   //     authVerify={authVerify}
//   //   /> 
//   // : authVerify === true
//   // ?
//     <LensQuestions
//       whyLens={whyLens}
//       setWhyLens={setWhyLens}
//       ptLensList={ptLensList}
//       lensOption={lensOption}
//     />
//   // : null
// }
// />