import React from 'react'

export default function Pittfalls() {
    let backgroundStyle = {
        background: "#292929",
        padding: '0 0 0px 0',
    }

    let titleStyle = {
        fontSize: '60px',
        color: '#FFF',
        textAlign: 'center',
        margin: '40px 0 0px 0',
    }
    
    
    let subtitleStyle = {
        fontSize: '46px',
        color: '#FFF',
        textAlign: 'center',
        margin: '20px 0 20px 0',
    }

  return (
    <div style={{width: '100%'}}>
        <div
            style={{display: 'flex'}}
        >
            <div style={{width: '7vw'}}></div>
            <div style={{width: '84vw'}}>
                
                <div style={subtitleStyle}>
                    Where does your cataract process get stuck?
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div 
                        style={{
                            background: "#F7F0AD", 
                            width: '30%',
                            borderRadius: '5px',
                            padding: '5px 5px 20px 5px',
                        }}
                    >
                        <p style={{textAlign: 'center', margin: '0', fontSize: '30px'}}>
                            Patient
                        </p>
                        <ul>
                            <li>
                                I don't understand the difference between the lenses you are talking about. Could you go through them again? 
                            </li>
                            <li>
                                I want the lens that lets me see well at all distances AND I don't want halos at night.
                            </li>
                            <li>
                                What's astigmatism again?
                            </li>
                            <li>
                                I just had surgery, and I don't like the lens the doctor picked for me.
                            </li>
                            <li>
                                I just had surgery, and nobody told me I was going to loose my near vision.
                            </li>
                        </ul>
                    </div>
                    <div 
                        style={{
                            background: "#e7ccf1", 
                            width: '30%',
                            borderRadius: '5px',
                            padding: '5px 5px 20px 5px',
                        }}
                    >
                        <p style={{textAlign: 'center', margin: '0', fontSize: '30px'}}>
                            Staff
                        </p>
                        <ul>
                            <li>
                                Is the extended depth of focus the CCA0T0, CCWET0, or CCWTT0?
                            </li>
                            <li>
                                I wrote the correct eye everywhere else on the consent.
                            </li>
                            <li>
                                How much do we charge for the DXW lens?
                            </li>
                            <li>
                                Which doctors in our group are okay using an EDOF lens in patients with an epiretinal membrane?
                            </li>
                            <li>
                                I like this clinic, but I am leaving to take a new surgery coordinator job to be closer to family.
                            </li>
                            <li>
                                Sorry, I'm new to this, and I can't remember all these lens cuttoffs and contraindications.
                            </li>
                        </ul>
                    </div>
                    <div 
                        style={{
                            background: "#d2e5f6", 
                            width: '30%',
                            borderRadius: '5px',
                            padding: '5px 5px 20px 5px',
                        }}
                    >
                        <p style={{textAlign: 'center', margin: '0', fontSize: '30px'}}>
                            Doctors
                        </p>
                        <ul>
                            <li>
                                I wish these lens discussions didn't take so long.
                            </li>
                            <li>
                                How do I get into premium lenses?
                            </li>
                            <li>
                                My surgery coordinator is always so stressed.  I hope they don't quit.
                            </li>
                            <li>
                                This satellite clinic never has the right diagrams and consent forms.
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div style={{width: '7vw'}}></div>
        </div>
    </div>
                    
  )
}
